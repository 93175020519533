<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.user"
                          label="Usuario"
                          :rules="editedItem.userRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                            <v-checkbox class="ml-5"
                              label="Master"
                              v-model="editedItem.master"
                            ></v-checkbox>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre"
                          :rules="editedItem.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogPermits" max-width="1100px">
            <v-card>
              <v-card-title>
                <span class="headline">Permisos</span>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-subtitle>
                Agregar Permisos a <strong> {{editedItem.name}} </strong>
              </v-card-subtitle>
              <v-card-text>
                <v-container>
                  <!-- LINE 1 -->
                  <v-row>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-money-check-alt</v-icon>
                              Bancos
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.banks"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-city</v-icon>
                              Bienes
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.properties"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-university</v-icon>
                              Aseguradoras
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.insurers"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                  </v-row>

                  <!-- LINE 2 -->
                  <v-row>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-clinic-medical</v-icon>
                              Ramos
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.branches"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-users</v-icon>
                              Clientes
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.customers"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-person</v-icon>
                              Persona Juridica
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.companies"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                  </v-row>

                  <!-- LINE 3 -->
                  <v-row>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-person-circle-plus</v-icon>
                              Asegurados
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.policyholders"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-file-invoice-dollar</v-icon>
                              Pólizas
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.policies"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-file-medical</v-icon>
                              Reporte de Pólizas
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.reportPolicies"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                  </v-row>

                  <!-- LINE 4 -->
                  <v-row>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fas fa-money-bill-alt</v-icon>
                              Cobranza
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.collections"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-card
                        color="#1E333F"
                        dark
                      >
                      <v-row dense>
                        <v-col cols="9">
                          <v-card-title class="text-h6 d-flex justify-start">
                              <v-icon class="mr-2">fab fa-cc-apple-pay</v-icon>
                              Reporte de Pagos
                          </v-card-title>
                        </v-col>
                        
                        <v-col cols="3" class="d-flex justify-end pr-5">
                            <v-checkbox class="ml-5"
                              v-model="editedPermits.reportPays"
                            ></v-checkbox>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="savePermits">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>¿Desea eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogResetPassword" max-width="500">
            <v-card>
              <v-card-title>
                <span>¿Restablecer contraseña de {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text @click="resetPass">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
        </v-toolbar>
      </template>

      <template v-slot:[`item.master`]="{ item }">
        <v-icon v-if="item.master == '1'" class="mr-2">fas fa-check</v-icon>
        <v-icon v-else class="mr-2">fas fa-times</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">

        <v-tooltip top color="gray">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-2" @click="resetPassword(item)">fas fa-unlock</v-icon>
          </template>
          <span>Restablecer Contraseña</span>
        </v-tooltip>

        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="purple" v-if="item.master == '0'" class="mr-2" @click="getPermits(item)">fas fa-user-cog</v-icon>
          </template>
          <span>Agregar Permisos</span>
        </v-tooltip>

        
        
        <v-icon color="success" class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getUsers">Recargar información</v-btn>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Usuarios",
  data: () => ({
    dialog: false,
    users: [],
    permisos: [],
    dialogDelete: false,
    dialogPermits: false,
    dialogResetPassword: false,
    search: "",
    loading: "",
    titulo: "Usuarios",
    headers: [
      { text: "Nombre", align: "left", value: "name" },
      { text: "Usuario", value: "user" },
      { text: "Master", value: "master" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    editedItem: {
      id: "",
      user: "",
      name: "",
      master: false,
      process: "",
      nameRules: [v => !!v || "Escriba un nombre"],
      typeRules: [v => !!v || "Escoge un tipo de usuario"],
      userRules: [v => !!v || "Escriba un usuario"],
    },
    defaultItem: {
      user: "",
      name: "",
      master: false,
      process: "",
      nameRules: [v => !!v || "Escriba un nombre"],
      typeRules: [v => !!v || "Escoge un tipo de usuario"],
      userRules: [v => !!v || "Escriba un usuario"],
    },

    editedPermits: {
      banks: false,
      properties: false,
      insurers: false,
      branches: false,
      customers: false,
      companies: false,
      policyholders: false,
      policies: false,
      reportPolicies: false,
      collections: false,
      reportPays: false,
    },
    
    defaultPermits: {
      banks: false,
      properties: false,
      insurers: false,
      branches: false,
      customers: false,
      companies: false,
      policyholders: false,
      policies: false,
      reportPolicies: false,
      collections: false,
      reportPays: false,
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),
    // Metodos base de datos
    async getUsers(){
        await axios.get(this.db + 'users',
        {
          headers: this.headers_db
        }).then(response => {
          this.users = response.data.data;
          this.users.forEach(element => {
            element.master = element.master == '1' ? true : false;
          });
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          this.validateSession(error).then((resp) => { if(resp) this.getUsers(); })
        });
    },

    resetPass() {
      axios.post(this.db + 'reset_password', 
      { id: this.editedItem.id },
      { headers: this.headers_db })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: "Se ha restablecido la contraseña de " + this.editedItem.name,
        });
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.resetPass(); })
      });
    },

    async getPermits(item){
      console.log("Item ", item);

      await axios.get(this.db + 'permits/'+ item.id,
        {
          headers: this.headers_db
        }).then(response => {
          this.permisos = response.data.data;
          console.log(this.permisos);
          if (this.permisos.banks == '1') this.editedPermits.banks = true;
          if (this.permisos.properties == '1') this.editedPermits.properties = true;
          if (this.permisos.insurers == '1') this.editedPermits.insurers = true;
          if (this.permisos.branches == '1') this.editedPermits.branches = true;
          if (this.permisos.customers == '1') this.editedPermits.customers = true;
          if (this.permisos.companies == '1') this.editedPermits.companies = true;
          if (this.permisos.policyholders == '1') this.editedPermits.policyholders = true;
          if (this.permisos.policies == '1') this.editedPermits.policies = true;
          if (this.permisos.reportPolicies == '1') this.editedPermits.reportPolicies = true;
          if (this.permisos.collections == '1') this.editedPermits.collections = true;
          if (this.permisos.reportPays == '1') this.editedPermits.reportPays = true;
        })
        .catch((error) => {
          console.log(error);
          this.validateSession(error).then((resp) => { if(resp) this.getPermits(item); })
        });
      
      this.editedItem = Object.assign({}, item);
      this.dialogPermits = true;
    },

    addUser(payload){
      axios.post(this.db + 'user/add', {
        user: payload.user,
        master: payload.master ? 1:0,
        name: payload.name,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getUsers();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addUser(payload); })
      });

    },
    
    updateUser(payload){

      axios.post(this.db + 'user/update', {
        id: payload.id,
        user: payload.user,
        master: payload.master ? 1:0,
        name: payload.name,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getUsers();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateUser(payload); })
      });

    },

    deleteUser(id){

      axios.post(this.db + 'user/delete', {
        id
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.users = this.users.filter(doc => {
          return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteUser(id); })
      });

    },
    // FIN Metodos base de datos


    // Metodos Generales

    editItem(item) {
      console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    resetPassword(item) {
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialogResetPassword = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogPermits = false;
      this.dialogResetPassword = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedPermits = Object.assign({}, this.defaultPermits);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem);
          this.updateUser(this.editedItem);
        } else {
          this.users.push(this.editedItem);
          this.addUser(this.editedItem);
        }
        this.close();
      }
    },

    savePermits() {
      let payload = this.editedPermits;
      console.log(payload);
      axios.post(this.db + 'permits/update', {
        id: this.editedItem.id,
        banks: payload.banks ? 1:0,
        properties: payload.properties ? 1:0,
        insurers: payload.insurers ? 1:0,
        branches: payload.branches ? 1:0,
        customers: payload.customers ? 1:0,
        companies: payload.companies ? 1:0,
        policyholders: payload.policyholders ? 1:0,
        policies: payload.policies ? 1:0,
        reportPolicies: payload.reportPolicies ? 1:0,
        collections: payload.collections ? 1:0,
        reportPays: payload.reportPays ? 1:0,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.savePermits(); })
      });
    },

    eliminar() {
      this.deleteUser(this.editedItem.id);
      this.close();
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

